import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
// import TableChartIcon from "@material-ui/icons/TableChart";

import PeopleIcon from "@material-ui/icons/People";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import ArchiveIcon from "@material-ui/icons/Archive";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import SettingsIcon from "@material-ui/icons/Settings";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles, List, fade } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";

import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import WorkIcon from "@material-ui/icons/Work";
// import AssessmentIcon from "@material-ui/icons/Assessment";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import clsx from "clsx";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(2.5),
  },
  activeBorder: {
    background: "rgba( 255, 255, 255, 0.05 )",
    backdropFilter: "blur(10px)",
    borderRight: "3px solid #e33371",
  },
  activeInnerBorder: {
    borderRight: `3px solid ${fade("#009688", 0.6)}`,
  },
  active: {
    color: fade("#e33371", 0.8),
  },
  activeInner: {
    color: fade("#009688", 0.8),
  },
  hoverBorder: {
    "&:hover": {
      background: "rgba( 255, 255, 255, 0.05 )",
      backdropFilter: "blur(10px)",
      color: "#e33371",
      borderRight: "3px solid #e33371",
      transition: theme.transitions.create("all", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.leavingScreen,
      }),
      animation: "$fadeIn 400ms cubic-bezier(0.4, 0, 0.2, 1)",
    },
  },
  "@keyframes fadeIn": {
    from: {
      opacity: 0.5,
      color: fade("#e33371", 0.8),
      borderRight: `3px solid ${fade("#e33371", 0.8)}`,
    },
    to: {
      opacity: 1,
      color: "#e33371",
      borderRight: "3px solid #e33371",
    },
  },
  hoverInnerBorder: {
    "&:hover": {
      borderRight: "3px solid #009688",
      transition: theme.transitions.create("border", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  iconColor: {
    color:
      theme.palette.type === "light"
        ? theme.palette.background.paper
        : "#fafafa",
  },
  iconColor2: {
    color:
      theme.palette.type === "light"
        ? theme.palette.background.paper
        : "#fafafa",
  },
}));

export const VendorListItems = ({ vendor }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [loader, setLoader] = React.useState(false);
  // const [open, setOpen] = React.useState({
  //   quality: false,
  //   budgets: false,
  //   hr: false,
  //   inventory: false,
  //   cashadvance: false,
  // });

  // const handleDropdown = (name) => {
  //   setOpen((prevState) => ({
  //     ...prevState,
  //     [name]: !open[name],
  //   }));
  // };

  React.useEffect(() => {
    setLoader((prev) => !prev);
    setTimeout(() => {
      setLoader(false);
    }, 4000);
  }, []);

  const matchRoute = (path) => {
    return location.pathname === path;
  };

  const routerLink = (path) => {
    history.push(path);
  };

  // let isVendor =
  //   vendor &&
  //   vendor &&
  //   vendor.groups &&
  //   vendor.groups.some((grp) => grp.name === "vendor");
  return (
    <div>
      {loader ? (
        <div style={{ padding: 20 }}>
          <Skeleton
            width="100%"
            height={218}
            variant="rectangular"
            style={{ background: "#263238", padding: 10, marginTop: 0 }}
          />
        </div>
      ) : (
        <>
          <ListItem
            button
            className={clsx(
              matchRoute("/vendors") && classes.activeBorder,
              classes.hoverBorder
            )}
            onClick={() => routerLink("/vendors")}
          >
            <ListItemIcon>
              <DashboardIcon className={classes.iconColor} />
            </ListItemIcon>
            <ListItemText
              primary="Dashboard"
              className={clsx(matchRoute("/vendors") && classes.active)}
            />
          </ListItem>

          <ListItem
            button
            className={clsx(
              matchRoute("/vendors/quote") && classes.activeBorder,
              classes.hoverBorder
            )}
            onClick={() => routerLink("/vendors/quote")}
          >
            <ListItemIcon>
              <AssignmentTurnedInIcon className={classes.iconColor} />
            </ListItemIcon>
            <ListItemText
              primary="Quotation"
              className={clsx(matchRoute("/vendors/quote") && classes.active)}
            />
          </ListItem>
        </>
      )}
    </div>
  );
};

export const MainListItems = ({ profile, vendor }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [loader, setLoader] = React.useState(false);
  const [open, setOpen] = React.useState({
    quality: false,
    cue: false,
    budgets: false,
    hr: false,
    inventory: false,
    cashadvance: false,
  });

  const handleDropdown = (name) => {
    setOpen((prevState) => ({
      ...prevState,
      [name]: !open[name],
    }));
  };

  React.useEffect(() => {
    setLoader((prev) => !prev);
    setTimeout(() => {
      setLoader(false);
    }, 4000);
  }, []);

  const matchRoute = (path) => {
    return location.pathname === path;
  };

  const routerLink = (path) => {
    history.push(path);
  };

  let isAdminAndFinance = profile
    ? profile &&
    profile.groups &&
    profile.groups.some(
      (grp) =>
        grp.name === "admin" ||
        (grp.name === "reviewer" && grp.module === "budget") ||
        (grp.name === "ceo" && grp.module === "budget")
    )
    : false;

  const isQHSE = profile ? profile.division && (profile.division.name === "Quality Health, Safety & Environment") : false;
  const isCUEorPPM = profile ? profile.division && ((profile.division.code === "CUE") || (profile.division.code === "PPM")) : false;

  const isQHSEorPMG = profile
        ? profile &&
        profile.division &&
        ((profile?.division?.code === "QHS") || (profile?.division?.code === "PMG"))
        : false;

  let isVendor =
    vendor &&
    vendor &&
    vendor.groups &&
    vendor.groups.some((grp) => grp.name === "vendor");

  let isFinanceHead = profile
    ? (profile &&
      profile.groups &&
      profile.groups.some(
        (grp) => grp.name === "payer" && grp.module === "cash_advance"
      )) ||
    (profile && profile.division && profile.division.name === "Finance")
    : false;

  let isPeoplesManagement = profile
    ? (profile &&
      profile.groups &&
      profile.groups.some(
        (grp) =>
          grp.name === "admin" ||
          (grp.name === "recovery" && grp.module === "cash_advance")
      )) ||
    (profile &&
      profile.division &&
      profile.division.name === "People Management")
    : false;

  let isPeoplesGuard = profile
    ? (profile &&
      profile.groups &&
      profile.groups.some(
        (grp) =>
          grp.name === "admin" ||
          (grp.name === "user" && grp.module === "ppm")
      )) ||
    (profile &&
      profile.division &&
      profile.division.name === "People Management")
    : false;

  let isAdminAndInventory = profile
    ? profile &&
    profile.groups &&
    profile.groups.some(
      (grp) => (grp.name === "admin" ||
        (grp.name === "manager" && grp.module === "inventory"))
    ) : false;

  let isSolutionsGuard = profile
    ? (profile &&
      profile.division &&
      profile.division.name === "Solutions")
    : false;

  let isAdminOnly = profile
    ? profile &&
    profile.groups &&
    profile.groups.some((grp) => grp.name === "admin")
    : false;

  return (
    <div>
      {loader ? (
        <div style={{ padding: 20 }}>
          <Skeleton
            width="100%"
            height={218}
            variant="rectangular"
            style={{ background: "#263238", padding: 10, marginTop: 0 }}
          />
        </div>
      ) : (
        <>
          <ListItem
            button
            className={clsx(
              matchRoute("/dashboard") && classes.activeBorder,
              classes.hoverBorder
            )}
            onClick={() => routerLink("/dashboard")}
          >
            <ListItemIcon>
              <DashboardIcon className={classes.iconColor} />
            </ListItemIcon>
            <ListItemText
              primary="Dashboard"
              className={clsx(matchRoute("/dashboard") && classes.active)}
            />
          </ListItem>

          {isVendor && (
            <ListItem
              button
              className={clsx(
                matchRoute("/vendors/quote") && classes.activeBorder,
                classes.hoverBorder
              )}
              onClick={() => routerLink("/vendors/quote")}
            >
              <ListItemIcon>
                <AssignmentTurnedInIcon className={classes.iconColor} />
              </ListItemIcon>
              <ListItemText
                primary="Quotation"
                className={clsx(matchRoute("/vendors/quote") && classes.active)}
              />
            </ListItem>
          )}

          {isVendor ? null : (
            <>
              {/* <ListItem
                button
                className={clsx(
                  matchRoute("/mycourses") && classes.activeBorder,
                  classes.hoverBorder
                )}
                onClick={() => routerLink("/mycourses")}
              >
                <ListItemIcon>
                  <TableChartIcon className={classes.iconColor} />
                </ListItemIcon>
                <ListItemText
                  primary="Courses & Lessons"
                  className={clsx(matchRoute("/mycourses") && classes.active)}
                />
              </ListItem> */}
            
              
                  <ListItem
                    button
                    onClick={() => handleDropdown("quality")}
                    className={classes.hoverBorder}
                  >
                    <ListItemIcon>
                      <PeopleIcon className={classes.iconColor} />
                    </ListItemIcon>
                    <ListItemText primary="Quality Mgt." />
                    {open.quality ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={open.quality} timeout="auto" unmountOnExit>
                    <List disablePadding>
                      
                      <ListItem
                        button
                        onClick={() => routerLink("/quality/receivinginspection")}
                        className={clsx(
                          matchRoute("/quality/receivinginspection") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                      >
                        <ListItemIcon 
                          className={clsx(
                            matchRoute("/quality/receivinginspection")
                              ? classes.active
                              : classes.iconColor2
                          )}>
                          RI
                        </ListItemIcon>
                        <ListItemText 
                          primary="Receiving & Inspec." 
                          className={clsx(
                            matchRoute("/quality/receivinginspection") && classes.active
                          )}/>
                      </ListItem>

                      <ListItem
                    button
                    onClick={() => routerLink("/quality/workcompletion")}
                    className={clsx(
                      matchRoute("/quality/workcompletion") && classes.activeBorder,
                      classes.hoverBorder,
                      classes.nested
                    )}
                  >
                    <ListItemIcon  className={clsx(
                        matchRoute("/quality/workcompletion")
                          ? classes.active
                          : classes.iconColor2
                      )}>
                      WC
                    </ListItemIcon>
                    <ListItemText 
                      primary="Work Completion" 
                      className={clsx(
                        matchRoute("/quality/workcompletion") && classes.active
                      )}/>
                  </ListItem>

                  {isQHSEorPMG && (
                    <>
                      <ListItem
                        button
                        onClick={() => routerLink("/quality/vendors")}
                        className={clsx(
                          matchRoute("/quality/vendors") &&
                          classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/quality/vendors")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          VL
                        </ListItemIcon>
                        <ListItemText
                          primary="Vendors List"
                          className={clsx(
                            matchRoute("/quality/vendors") && classes.active
                          )}
                        />
                      </ListItem>

                      <ListItem
                        button
                        onClick={() => routerLink("/quality/products")}
                        className={clsx(
                          matchRoute("/quality/products") &&
                          classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/quality/products")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          PS
                        </ListItemIcon>
                        <ListItemText
                          primary="Prod./Serv."
                          className={clsx(
                            matchRoute("/quality/products") && classes.active
                          )}
                        />
                      </ListItem>
                      </>
                    )}
                    </List>
                  </Collapse>
                
              <>
                {/* <ListItem
                  onClick={() => routerLink("/kpi")}
                  button
                  className={classes.hoverBorder}
                >
                  <ListItemIcon
                    className={clsx(
                      matchRoute("/kpi") ? classes.active : classes.iconColor2
                    )}
                  >
                    <AssessmentIcon className={classes.iconColor} />
                  </ListItemIcon>
                  <ListItemText
                    primary="KPI"
                    className={clsx(matchRoute("/kpi") && classes.active)}
                  />
                </ListItem> */}

                <ListItem
                  button
                  onClick={() => handleDropdown("cue")}
                  className={classes.hoverBorder}
                >
                  <ListItemIcon>
                    <VerifiedUserIcon className={classes.iconColor} />
                  </ListItemIcon>
                  <ListItemText primary="Visitor Mgt." />
                  {open.cue ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open.cue} timeout="auto" unmountOnExit>
                  <List disablePadding>
                    <ListItem
                      button
                      onClick={() => routerLink("/visitor/appointments")}
                      className={clsx(
                        matchRoute("/visitor/appointments") && classes.activeBorder,
                        classes.hoverBorder,
                        classes.nested
                      )}
                    >
                      <ListItemIcon className={clsx(matchRoute("/visitor/appointments") ? classes.active : classes.iconColor2)}>
                        VR
                      </ListItemIcon>
                      <ListItemText primary="Visitor Appointment" className={clsx(matchRoute("/visitor/appointments") && classes.active)} />
                    </ListItem>

                    {(isCUEorPPM) ?
                    
                      <ListItem
                        button
                        onClick={() => routerLink("/visitor/all")}
                        className={clsx(
                          matchRoute("/visitor/all") &&
                          classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                      >
                        <ListItemIcon className={clsx(matchRoute("/visitor/all") ? classes.active : classes.iconColor2)} >
                          VL
                        </ListItemIcon>
                        <ListItemText primary="Visitor Log" className={clsx(matchRoute("/visitor/all") && classes.active)} />
                      </ListItem>
                      : null}

                      {(isCUEorPPM || isAdminOnly) ?
                      <>
                      <ListItem
                      button
                      onClick={() => routerLink("/visitor/checkin")}
                      className={clsx(
                        matchRoute("/visitor/checkin") &&
                        classes.activeBorder,
                        classes.hoverBorder,
                        classes.nested
                      )}
                      >
                      <ListItemIcon className={clsx(matchRoute("/visitor/checkin") ? classes.active : classes.iconColor2)} >
                        VI
                      </ListItemIcon>
                      <ListItemText primary="Check Visitor In" className={clsx(matchRoute("/visitor/checkin") && classes.active)} />
                      </ListItem>

                      <ListItem
                      button
                      onClick={() => routerLink("/visitor/checkout")}
                      className={clsx(
                        matchRoute("/visitor/checkout") &&
                        classes.activeBorder,
                        classes.hoverBorder,
                        classes.nested
                      )}
                      >
                      <ListItemIcon className={clsx(matchRoute("/visitor/checkout") ? classes.active : classes.iconColor2)} >
                        VO
                      </ListItemIcon>
                      <ListItemText primary="Check Visitor Out" className={clsx(matchRoute("/visitor/checkout") && classes.active)} />
                      </ListItem>
                      </>
                      : null}

                    {isQHSE ?
                      <ListItem
                        button
                        onClick={() => routerLink("/emergencylist")}
                        className={clsx(
                          matchRoute("/emergencylist") &&
                          classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                      >
                        <ListItemIcon className={clsx(matchRoute("/emergencylist") ? classes.active : classes.iconColor2)} >
                          EL
                        </ListItemIcon>
                        <ListItemText primary="Emergency Head Count" className={clsx(matchRoute("/emergencylist") && classes.active)} />
                      </ListItem>
                      : null}

                  </List>
                </Collapse>

              </>

              {isPeoplesGuard && (
                <>
                  <ListItem
                    button
                    onClick={() => handleDropdown("hr")}
                    className={classes.hoverBorder}
                  >
                    <ListItemIcon>
                      <EmojiPeopleIcon className={classes.iconColor} />
                    </ListItemIcon>
                    <ListItemText primary="PPM" />
                    {open.hr ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>

                  <Collapse in={open.hr} timeout="auto" unmountOnExit>
                    <List disablePadding>
                      <ListItem
                        button
                        onClick={() => routerLink("/hr/attendance")}
                        className={clsx(
                          matchRoute("/hr/attendance") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/hr/attendance")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          AT
                        </ListItemIcon>
                        <ListItemText
                          primary="Attendance"
                          className={clsx(
                            matchRoute("/hr/attendance") && classes.active
                          )}
                        />
                      </ListItem>
                      
                      <ListItem
                        button
                        onClick={() => routerLink("/hr/attendancereport")}
                        className={clsx(
                          matchRoute("/hr/attendancereport") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/hr/attendancereport")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          AR
                        </ListItemIcon>
                        <ListItemText
                          primary="Attendance Report"
                          className={clsx(
                            matchRoute("/hr/attendancereport") && classes.active
                          )}
                        />
                      </ListItem>

                      <ListItem
                        onClick={() => routerLink("/ppm/employees")}
                        button
                        className={clsx(
                          matchRoute("/ppm/employees") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/ppm/employees")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          EMP
                        </ListItemIcon>
                        <ListItemText
                          primary="All Employees"
                          className={clsx(
                            matchRoute("/ppm/employees") && classes.active
                          )}
                        />
                      </ListItem>
                      <ListItem
                        button
                        className={clsx(
                          matchRoute("/ppm/jobtitles") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                        onClick={() => routerLink("/ppm/jobtitles")}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/ppm/jobtitles")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          JTS
                        </ListItemIcon>
                        <ListItemText
                          primary="Setup Job Titles"
                          className={clsx(
                            matchRoute("/ppm/jobtitles") && classes.active
                          )}
                        />
                      </ListItem>
                      

                      {/* <ListItem
                        button
                        className={clsx(
                          matchRoute("/lms/courses") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                        onClick={() => routerLink("/lms/courses")}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/lms/courses")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          JTS
                        </ListItemIcon>
                        <ListItemText
                          primary="Setup Courses"
                          className={clsx(
                            matchRoute("/lms/courses") && classes.active
                          )}
                        />
                      </ListItem> */}


                      

                    </List>
                  </Collapse>
                </>
              )}

              <ListItem
                button
                onClick={() => handleDropdown("budgets")}
                className={classes.hoverBorder}
              >
                <ListItemIcon>
                  <MonetizationOnIcon className={classes.iconColor} />
                </ListItemIcon>
                <ListItemText primary="Budgets" />
                {open.budgets ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open.budgets} timeout="auto" unmountOnExit>
                <List disablePadding>
                  {isAdminAndFinance && (
                    <>
                      <ListItem
                        button
                        onClick={() => routerLink("/budget/all/annual")}
                        className={clsx(
                          matchRoute("/budget/all/annual") &&
                          classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/budget/all/annual")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          AAB
                        </ListItemIcon>
                        <ListItemText
                          primary="All Annual Budgets"
                          className={clsx(
                            matchRoute("/budget/all/annual") && classes.active
                          )}
                        />
                      </ListItem>

                      <ListItem
                        button
                        onClick={() => routerLink("/budget/all/review")}
                        className={clsx(
                          matchRoute("/budget/all/review") &&
                          classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/budget/all/review")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          AR
                        </ListItemIcon>
                        <ListItemText
                          primary="Annual Review"
                          className={clsx(
                            matchRoute("/budget/all/review") && classes.active
                          )}
                        />
                      </ListItem>

                      <ListItem
                        button
                        onClick={() => routerLink("/budget/all/monthly")}
                        className={clsx(
                          matchRoute("/budget/all/monthly") &&
                          classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/budget/all/monthly")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          AMSB
                        </ListItemIcon>
                        <ListItemText
                          primary="All Mth./Sup. Budgets"
                          className={clsx(
                            matchRoute("/budget/all/monthly") && classes.active
                          )}
                        />
                      </ListItem>

                      <ListItem
                        button
                        onClick={() => routerLink("/currency")}
                        className={clsx(
                          matchRoute("/currency") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/currency")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          CU
                        </ListItemIcon>
                        <ListItemText
                          primary="Currency"
                          className={clsx(
                            matchRoute("/currency") && classes.active
                          )}
                        />
                      </ListItem>

                      <ListItem
                        button
                        onClick={() => routerLink("/costcenter")}
                        className={clsx(
                          matchRoute("/costcenter") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/costcenter")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          CC
                        </ListItemIcon>
                        <ListItemText
                          primary="Cost Center"
                          className={clsx(
                            matchRoute("/costcenter") && classes.active
                          )}
                        />
                      </ListItem>

                      <ListItem
                        button
                        onClick={() => routerLink("/budget/head")}
                        className={clsx(
                          matchRoute("/budget/head") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/budget/head")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          EH
                        </ListItemIcon>
                        <ListItemText
                          primary="Expense Head"
                          className={clsx(
                            matchRoute("/budget/head") && classes.active
                          )}
                        />
                      </ListItem>
                      <ListItem
                        onClick={() => routerLink("/budget/subhead")}
                        button
                        className={clsx(
                          matchRoute("/budget/subhead") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/budget/subhead")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          ESH
                        </ListItemIcon>
                        <ListItemText
                          primary="Expense SubHead"
                          className={clsx(
                            matchRoute("/budget/subhead") && classes.active
                          )}
                        />
                      </ListItem>
                      <ListItem
                        onClick={() => routerLink("/budget/item")}
                        button
                        className={clsx(
                          matchRoute("/budget/item") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/budget/item")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          BC
                        </ListItemIcon>
                        <ListItemText
                          primary="Budget Category"
                          className={clsx(
                            matchRoute("/budget/item") && classes.active
                          )}
                        />
                      </ListItem>
                    </>
                  )}

                  <ListItem
                    onClick={() => routerLink("/budget/annual")}
                    button
                    className={clsx(
                      matchRoute("/budget/annual") && classes.activeBorder,
                      classes.hoverBorder,
                      classes.nested
                    )}
                  >
                    <ListItemIcon
                      className={clsx(
                        matchRoute("/budget/annual")
                          ? classes.active
                          : classes.iconColor2
                      )}
                    >
                      AB
                    </ListItemIcon>
                    <ListItemText
                      primary="Annual Budgets"
                      className={clsx(
                        matchRoute("/budget/annual") && classes.active
                      )}
                    />
                  </ListItem>

                  {/* <ListItem
                    onClick={() => routerLink("/budget/utilization")}
                    button
                    className={clsx(
                      matchRoute("/budget/utilization") && classes.activeBorder,
                      classes.hoverBorder,
                      classes.nested
                    )}
                  >
                    <ListItemIcon
                      className={clsx(
                        matchRoute("/budget/utilization")
                          ? classes.active
                          : classes.iconColor2
                      )}
                    >
                      BUT
                    </ListItemIcon>
                    <ListItemText
                      primary="Budget Utilization"
                      className={clsx(
                        matchRoute("/budget/utilization") && classes.active
                      )}
                    />
                  </ListItem>  */}

                  <ListItem
                    onClick={() => routerLink("/budget/monthly")}
                    button
                    className={clsx(
                      matchRoute("/budget/monthly") && classes.activeBorder,
                      classes.hoverBorder,
                      classes.nested
                    )}
                  >
                    <ListItemIcon
                      className={clsx(
                        matchRoute("/budget/monthly")
                          ? classes.active
                          : classes.iconColor2
                      )}
                    >
                      MSB
                    </ListItemIcon>
                    <ListItemText
                      primary="Mnth/Sup Budgets"
                      className={clsx(
                        matchRoute("/budget/monthly") && classes.active
                      )}
                    />
                  </ListItem>
                </List>
              </Collapse>

              {/* Cash Advance */}
              <ListItem
                button
                onClick={() => handleDropdown("cashadvance")}
                className={classes.hoverBorder}
              >
                <ListItemIcon>
                  <AccountBalanceIcon className={classes.iconColor} />
                </ListItemIcon>
                <ListItemText primary="Cash Advance" />
                {open.cashadvance ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open.cashadvance} timeout="auto" unmountOnExit>
                <List disablePadding>
                  {isFinanceHead && (
                    <ListItem
                      onClick={() => routerLink("/cash/payment")}
                      button
                      className={clsx(
                        matchRoute("/cash/payment") && classes.activeBorder,
                        classes.hoverBorder,
                        classes.nested
                      )}
                    >
                      <ListItemIcon
                        className={clsx(
                          matchRoute("/cash/payment")
                            ? classes.active
                            : classes.iconColor2
                        )}
                      >
                        PT
                      </ListItemIcon>
                      <ListItemText
                        primary="Payment"
                        className={clsx(
                          matchRoute("/cash/payment") && classes.active
                        )}
                      />
                    </ListItem>
                  )}

                  <ListItem
                    onClick={() => routerLink("/cashadvance")}
                    button
                    className={clsx(
                      matchRoute("/cashadvance") && classes.activeBorder,
                      classes.hoverBorder,
                      classes.nested
                    )}
                  >
                    <ListItemIcon
                      className={clsx(
                        matchRoute("/cashadvance")
                          ? classes.active
                          : classes.iconColor2
                      )}
                    >
                      AD
                    </ListItemIcon>
                    <ListItemText
                      primary="Advance"
                      className={clsx(
                        matchRoute("/cashadvance") && classes.active
                      )}
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={clsx(
                      matchRoute("/reimbursement") && classes.activeBorder,
                      classes.hoverBorder,
                      classes.nested
                    )}
                    onClick={() => routerLink("/reimbursement")}
                  >
                    <ListItemIcon
                      className={clsx(
                        matchRoute("/reimbursement")
                          ? classes.active
                          : classes.iconColor2
                      )}
                    >
                      RM
                    </ListItemIcon>
                    <ListItemText
                      primary="Reimbursement"
                      className={clsx(
                        matchRoute("/reimbursement") && classes.active
                      )}
                    />
                  </ListItem>
                  {isAdminAndFinance && (
                    <>
                      <ListItem
                        button
                        className={clsx(
                          matchRoute("/recovery/review") &&
                          classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                        onClick={() => routerLink("/recovery/review")}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/recovery/review")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          RC
                        </ListItemIcon>
                        <ListItemText
                          primary="Recovery Review"
                          className={clsx(
                            matchRoute("/recovery/review") && classes.active
                          )}
                        />
                      </ListItem>
                    </>
                  )}

                  {isPeoplesManagement && (
                    <ListItem
                      button
                      className={clsx(
                        matchRoute("/recovery/log") && classes.activeBorder,
                        classes.hoverBorder,
                        classes.nested
                      )}
                      onClick={() => routerLink("/recovery/log")}
                    >
                      <ListItemIcon
                        className={clsx(
                          matchRoute("/recovery/log")
                            ? classes.active
                            : classes.iconColor2
                        )}
                      >
                        RL
                      </ListItemIcon>
                      <ListItemText
                        primary="Recovery Log"
                        className={clsx(
                          matchRoute("/recovery/log") && classes.active
                        )}
                      />
                    </ListItem>
                  )}
                </List>
              </Collapse>
              {/* END CASH ADVANCE */}

              {/* START INVENTORY MGT */}
              <ListItem
                button
                onClick={() => handleDropdown("inventory")}
                className={classes.hoverBorder}
              >
                <ListItemIcon>
                  <ArchiveIcon className={classes.iconColor} />
                </ListItemIcon>
                <ListItemText primary="Inventory Mgt." />
                {open.inventory ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open.inventory} timeout="auto" unmountOnExit>
                <List disablePadding>
                  {isAdminAndInventory && (
                    <>
                      <ListItem
                        onClick={() => routerLink("/inventory/stores")}
                        button
                        className={clsx(
                          matchRoute("/inventory/stores") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/inventory/stores")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          STM
                        </ListItemIcon>
                        <ListItemText
                          primary="Store Management"
                          className={clsx(
                            matchRoute("/inventory/stores") && classes.active
                          )}
                        />
                      </ListItem>

                      <ListItem
                        onClick={() => routerLink("/inventory/locations")}
                        button
                        className={clsx(
                          matchRoute("/inventory/locations") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/inventory/locations")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          STL
                        </ListItemIcon>
                        <ListItemText
                          primary="Store Locations"
                          className={clsx(
                            matchRoute("/inventory/locations") && classes.active
                          )}
                        />
                      </ListItem>

                      <ListItem
                        onClick={() => routerLink("/inventory/storeitems")}
                        button
                        className={clsx(
                          matchRoute("/inventory/storeitems") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/inventory/storeitems")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          SI
                        </ListItemIcon>
                        <ListItemText
                          primary="Inventory Items"
                          className={clsx(
                            matchRoute("/inventory/storeitems") && classes.active
                          )}
                        />
                      </ListItem>

                      <ListItem
                        onClick={() => routerLink("/inventory/categories")}
                        button
                        className={clsx(
                          matchRoute("/inventory/categories") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/inventory/categories")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          IC
                        </ListItemIcon>
                        <ListItemText
                          primary="Inventory Categories"
                          className={clsx(
                            matchRoute("/inventory/categories") && classes.active
                          )}
                        />
                      </ListItem>

                      <ListItem
                        onClick={() => routerLink("/inventory/count")}
                        button
                        className={clsx(
                          matchRoute("/inventory/count") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/inventory/count")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          IC
                        </ListItemIcon>
                        <ListItemText
                          primary="Inventory Count"
                          className={clsx(
                            matchRoute("/inventory/count") && classes.active
                          )}
                        />
                      </ListItem>

                      <ListItem
                        onClick={() => routerLink("/inventory/assetdatabase")}
                        button
                        className={clsx(
                          matchRoute("/inventory/assetdatabase") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/inventory/assetdatabase")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          AD
                        </ListItemIcon>
                        <ListItemText
                          primary="Assets Database"
                          className={clsx(
                            matchRoute("/inventory/assetdatabase") && classes.active
                          )}
                        />
                      </ListItem>
                      <ListItem
                        onClick={() => routerLink("/inventory/grn")}
                        button
                        className={clsx(
                          matchRoute("/inventory/grn") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/inventory/grn")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          GRN
                        </ListItemIcon>
                        <ListItemText
                          primary="Goods Received Note"
                          className={clsx(
                            matchRoute("/inventory/grn") && classes.active
                          )}
                        />
                      </ListItem>
                    </>
                  )}

                  {isSolutionsGuard && (
                    <>
                      <ListItem
                        onClick={() => routerLink("/inventory/equipments")}
                        button
                        className={clsx(
                          matchRoute("/inventory/equipments") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/inventory/equipments")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          IE
                        </ListItemIcon>
                        <ListItemText
                          primary="Inventory Equipments"
                          className={clsx(
                            matchRoute("/inventory/equipments") && classes.active
                          )}
                        />
                      </ListItem>
                    </>
                  )}

                  <ListItem
                    onClick={() => routerLink("/inventory/requisition")}
                    button
                    className={clsx(
                      matchRoute("/inventory/requisition") && classes.activeBorder,
                      classes.hoverBorder,
                      classes.nested
                    )}
                  >
                    <ListItemIcon
                      className={clsx(
                        matchRoute("/inventory/requisition")
                          ? classes.active
                          : classes.iconColor2
                      )}
                    >
                      IR
                    </ListItemIcon>
                    <ListItemText
                      primary="Inventory Requisition"
                      className={clsx(
                        matchRoute("/inventory/requisition") && classes.active
                      )}
                    />
                  </ListItem>

                  <ListItem
                    onClick={() => routerLink("/inventory/receipt")}
                    button
                    className={clsx(
                      matchRoute("/inventory/receipt") && classes.activeBorder,
                      classes.hoverBorder,
                      classes.nested
                    )}
                  >
                    <ListItemIcon
                      className={clsx(
                        matchRoute("/inventory/receipt")
                          ? classes.active
                          : classes.iconColor2
                      )}
                    >
                      IR
                    </ListItemIcon>
                    <ListItemText
                      primary="Inventory Receipt"
                      className={clsx(
                        matchRoute("/inventory/receipt") && classes.active
                      )}
                    />
                  </ListItem>
                  <ListItem
                    onClick={() => routerLink("/inventory/gatepass")}
                    button
                    className={clsx(
                      matchRoute("/inventory/gatepass") && classes.activeBorder,
                      classes.hoverBorder,
                      classes.nested
                    )}
                  >
                    <ListItemIcon
                      className={clsx(
                        matchRoute("/inventory/gatepass")
                          ? classes.active
                          : classes.iconColor2
                      )}
                    >
                      GP
                    </ListItemIcon>
                    <ListItemText
                      primary="Gate Pass"
                      className={clsx(
                        matchRoute("/inventory/gatepass") && classes.active
                      )}
                    />
                  </ListItem>
                </List>
              </Collapse>
              {/* END INVENTORY MGT */}
            </>
          )}
        </>
      )}
    </div>
  );
};

export const SecondaryListItems = ({ profile, vendor }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    setLoader((prev) => !prev);
    setTimeout(() => {
      setLoader(false);
    }, 4000);
  }, []);

  const [open, setOpen] = React.useState({
    sales: false,
    purchasing: false,
    asset: false,
    setup: false,
    ppm: false,
    crm: false,
    mpl: false,
  });

  const matchRoute = (path) => {
    return location.pathname === path;
  };

  const routerLink = (path) => {
    history.push(path);
  };

  const handleDropdown = (name) => {
    setOpen((prevState) => ({
      ...prevState,
      [name]: !open[name],
    }));
  };

  let isAdmin = profile
    ? profile &&
    profile.groups &&
    profile.groups.some((grp) => grp.name === "admin")
    : false;

  let isAdminFinComm = profile
        ? profile &&
        profile.division &&
        (
          (profile?.division?.code === "COM" || profile?.division?.code === "FIN") || 
          (profile.groups && profile.groups.some((grp) => grp.name === "admin"))
        )
        : false;

  let isQuotation = profile
    ? profile &&
    profile.groups &&
    profile.groups.some((grp) => (grp.name === "user" && grp.module === "sales") ||
      (grp.name === "ceo" && grp.module === "sales") ||
      (grp.name === "reviewer" && grp.module === "sales")
    ) : false;

  let isVendor =
    vendor &&
    vendor &&
    vendor.groups &&
    vendor.groups.some((grp) => grp.name === "vendor");

  let isProjectMobDivision = profile
      ? profile &&
      profile.division &&
      (profile?.division?.code === "COM" || 
      profile?.division?.code === "FIN" || 
      profile?.division?.code === "SMD" || 
      profile?.division?.code === "OPD" || 
      profile?.division?.code === "TSD" || 
      profile?.division?.code === "SSD" || 
      profile?.division?.code === "UAD" || 
      profile?.division?.code === "PPM" || 
      profile?.division?.code === "QHS")
      : false;

  return (
    <div>
      {loader ? (
        <div style={{ padding: 20 }}>
          <Skeleton
            width="100%"
            height={218}
            variant="rectangular"
            style={{ background: "#263238", padding: 10, marginTop: 0 }}
          />
        </div>
      ) : (
        <>
          {isVendor ? null : (
            <>
              <ListItem
                button
                onClick={() => handleDropdown("sales")}
                className={classes.hoverBorder}
              >
                <ListItemIcon>
                  <AttachMoneyIcon className={classes.iconColor} />
                </ListItemIcon>
                <ListItemText primary="Sales Mgt." />
                {open.sales ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open.sales} timeout="auto" unmountOnExit>
                <List disablePadding>
                  <ListItem
                    button
                    onClick={() => routerLink("/sales/pricelist")}
                    className={clsx(
                      matchRoute("/sales/pricelist") && classes.activeBorder,
                      classes.hoverBorder,
                      classes.nested
                    )}
                  >
                    <ListItemIcon
                      className={clsx(
                        matchRoute("/sales/pricelist")
                          ? classes.active
                          : classes.iconColor2
                      )}
                    >
                      MPL
                    </ListItemIcon>
                    <ListItemText
                      primary="Master price list"
                      className={clsx(
                        matchRoute("/sales/pricelist") && classes.active
                      )}
                    />
                  </ListItem>

                  {isAdminFinComm && (
                    <>
                      {/* <ListItem
                        button
                        onClick={() => routerLink("/sales/salesorder")}
                        className={clsx(
                          matchRoute("/sales/salesorder") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/sales/salesorder")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          SO
                        </ListItemIcon>
                        <ListItemText
                          primary="Sales Order"
                          className={clsx(
                            matchRoute("/sales/salesorder") && classes.active
                          )}
                        />
                      </ListItem> */}

                      <ListItem
                        button
                        onClick={() => routerLink("/sales/orderdatabase")}
                        className={clsx(
                          matchRoute("/sales/orderdatabase") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/sales/orderdatabase")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          CO
                        </ListItemIcon>
                        <ListItemText
                          primary="Order Database"
                          className={clsx(
                            matchRoute("/sales/orderdatabase") && classes.active
                          )}
                        />
                      </ListItem>
                  </>
                  )}

                  {isQuotation && (
                    <ListItem
                      button
                      onClick={() => routerLink("/sales/quotation")}
                      className={clsx(
                        matchRoute("/sales/quotation") && classes.activeBorder,
                        classes.hoverBorder,
                        classes.nested
                      )} >
                      <ListItemIcon
                        className={clsx(
                          matchRoute("/sales/quotation")
                            ? classes.active
                            : classes.iconColor2
                        )}>
                        QT
                      </ListItemIcon>
                      <ListItemText
                        primary="Quotation"
                        className={clsx(
                          matchRoute("/sales/quotation") && classes.active
                        )}
                      />
                    </ListItem>
                  )}

                  <ListItem
                    button
                    onClick={() => routerLink("/sales/service")}
                    className={clsx(
                      matchRoute("/sales/service") && classes.activeBorder,
                      classes.hoverBorder,
                      classes.nested
                    )}
                  >
                    <ListItemIcon
                      className={clsx(
                        matchRoute("/sales/service")
                          ? classes.active
                          : classes.iconColor2
                      )}
                    >
                      ST
                    </ListItemIcon>
                    <ListItemText
                      primary="Service Types"
                      className={clsx(
                        matchRoute("/sales/service") && classes.active
                      )}
                    />
                  </ListItem>

                  <ListItem
                    button
                    onClick={() => routerLink("/sales/cwsr")}
                    className={clsx(
                      matchRoute("/sales/cwsr") && classes.activeBorder,
                      classes.hoverBorder,
                      classes.nested
                    )}
                  >
                    <ListItemIcon
                      className={clsx(
                        matchRoute("/sales/cwsr")
                          ? classes.active
                          : classes.iconColor2
                      )}
                    >
                      CWSR
                    </ListItemIcon>
                    <ListItemText
                      primary="CWSR"
                      className={clsx(
                        matchRoute("/sales/cwsr") && classes.active
                      )}
                    />
                  </ListItem>
                </List>
              </Collapse>


              <ListItem
                button
                onClick={() => handleDropdown("asset")}
                className={classes.hoverBorder}
              >
                <ListItemIcon>
                  <FormatAlignCenterIcon className={classes.iconColor} />
                </ListItemIcon>
                <ListItemText primary="Assets" />
                {open.asset ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open.asset} timeout="auto" unmountOnExit>
                <List disablePadding>

                <ListItem
                    button
                    onClick={() => routerLink("/asset/acquisition")}
                    className={clsx(
                      matchRoute("/asset/acquisition") &&
                      classes.activeBorder,
                      classes.hoverBorder,
                      classes.nested
                    )}
                  >
                    <ListItemIcon
                      className={clsx(
                        matchRoute("/asset/acquisition")
                          ? classes.active
                          : classes.iconColor2
                      )}
                    >
                      AA
                    </ListItemIcon>
                    <ListItemText
                      primary="Asset Acquisition"
                      className={clsx(
                        matchRoute("/asset/acquisition") && classes.active
                      )}
                    />
                  </ListItem>

                  <ListItem
                    button
                    onClick={() => routerLink("/asset/requisition")}
                    className={clsx(
                      matchRoute("/asset/requisition") &&
                      classes.activeBorder,
                      classes.hoverBorder,
                      classes.nested
                    )}
                  >
                    <ListItemIcon
                      className={clsx(
                        matchRoute("/asset/requisition")
                          ? classes.active
                          : classes.iconColor2
                      )}
                    >
                      AR
                    </ListItemIcon>
                    <ListItemText
                      primary="Asset Requisition"
                      className={clsx(
                        matchRoute("/asset/requisition") && classes.active
                      )}
                    />
                  </ListItem>
                </List>
              </Collapse>



              <ListItem
                button
                onClick={() => handleDropdown("purchasing")}
                className={classes.hoverBorder}
              >
                <ListItemIcon>
                  <AccountBalanceWalletIcon className={classes.iconColor} />
                </ListItemIcon>
                <ListItemText primary="Purchasing" />
                {open.purchasing ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open.purchasing} timeout="auto" unmountOnExit>
                <List disablePadding>

                  <ListItem
                    button
                    onClick={() => routerLink("/purchasing/requisition")}
                    className={clsx(
                      matchRoute("/purchasing/requisition") &&
                      classes.activeBorder,
                      classes.hoverBorder,
                      classes.nested
                    )}
                  >
                    <ListItemIcon
                      className={clsx(
                        matchRoute("/purchasing/requisition")
                          ? classes.active
                          : classes.iconColor2
                      )}
                    >
                      PR
                    </ListItemIcon>
                    <ListItemText
                      primary="Purchase Req."
                      className={clsx(
                        matchRoute("/purchasing/requisition") && classes.active
                      )}
                    />
                  </ListItem>

                  <ListItem
                    button
                    onClick={() => routerLink("/purchasing/requestforquote")}
                    className={clsx(
                      matchRoute("/purchasing/requestforquote") &&
                      classes.activeBorder,
                      classes.hoverBorder,
                      classes.nested
                    )}
                  >
                    <ListItemIcon
                      className={clsx(
                        matchRoute("/purchasing/requestforquote")
                          ? classes.active
                          : classes.iconColor2
                      )}
                    >
                      RFQ
                    </ListItemIcon>
                    <ListItemText
                      primary="Request For Quote"
                      className={clsx(
                        matchRoute("/purchasing/requestforquote") && classes.active
                      )}
                    />
                  </ListItem>

                  <ListItem
                    button
                    onClick={() => routerLink("/purchasing/purchaseorder")}
                    className={clsx(
                      matchRoute("/purchasing/purchaseorder") && classes.activeBorder,
                      classes.hoverBorder,
                      classes.nested
                    )}
                  >
                    <ListItemIcon  className={clsx(
                        matchRoute("/purchasing/purchaseorder")
                          ? classes.active
                          : classes.iconColor2
                      )}>
                      PO
                    </ListItemIcon>
                    <ListItemText 
                      primary="Purchase Order" 
                      className={clsx(
                        matchRoute("/purchasing/purchaseorder") && classes.active
                      )}/>
                  </ListItem>

                  {/* <ListItem
                    button
                    onClick={() => routerLink("/purchasing/rejectionlogs")}
                    className={clsx(
                      matchRoute("/purchasing/rejectionlogs") && classes.activeBorder,
                      classes.hoverBorder,
                      classes.nested
                    )}
                  >
                    <ListItemIcon  className={clsx(
                        matchRoute("/purchasing/rejectionlogs")
                          ? classes.active
                          : classes.iconColor2
                      )}>
                      RL
                    </ListItemIcon>
                    <ListItemText 
                      primary="Rejection Logs" 
                      className={clsx(
                        matchRoute("/purchasing/rejectionlogs") && classes.active
                      )}/>
                  </ListItem> */}

                  <ListItem
                    button
                    onClick={() => routerLink("/purchasing/openmarket")}
                    className={clsx(
                      matchRoute("/purchasing/openmarket") && classes.activeBorder,
                      classes.hoverBorder,
                      classes.nested
                    )}
                  >
                    <ListItemIcon  className={clsx(
                        matchRoute("/purchasing/openmarket")
                          ? classes.active
                          : classes.iconColor2
                      )}>
                      OM
                    </ListItemIcon>
                    <ListItemText 
                      primary="Open Market" 
                      className={clsx(
                        matchRoute("/purchasing/openmarket") && classes.active
                      )}/>
                  </ListItem>
                  
                </List>
              </Collapse>

              <ListItem
                button
                onClick={() => handleDropdown("crm")}
                className={classes.hoverBorder}
              >
                <ListItemIcon>
                  <SupervisedUserCircleIcon className={classes.iconColor} />
                </ListItemIcon>
                <ListItemText primary="CRM" />
                {open.crm ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open.crm} timeout="auto" unmountOnExit>
                <List disablePadding>
                  <ListItem
                    button
                    onClick={() => routerLink("/crm/customers")}
                    className={clsx(
                      matchRoute("/crm/customers") && classes.activeBorder,
                      classes.hoverBorder,
                      classes.nested
                    )}
                  >
                    <ListItemIcon
                      className={clsx(
                        matchRoute("/crm/customers")
                          ? classes.active
                          : classes.iconColor2
                      )}
                    >
                      CU
                    </ListItemIcon>
                    <ListItemText
                      primary="Customers"
                      className={clsx(
                        matchRoute("/crm/customers") && classes.active
                      )}
                    />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => routerLink("/crm/contacts")}
                    className={clsx(
                      matchRoute("/crm/contacts") && classes.activeBorder,
                      classes.hoverBorder,
                      classes.nested
                    )}
                  >
                    <ListItemIcon
                      className={clsx(
                        matchRoute("/crm/contacts")
                          ? classes.active
                          : classes.iconColor2
                      )}
                    >
                      CT
                    </ListItemIcon>
                    <ListItemText
                      primary="Contacts"
                      className={clsx(
                        matchRoute("/crm/contacts") && classes.active
                      )}
                    />
                  </ListItem>

                  <ListItem
                    button
                    className={clsx(
                      matchRoute("/crm/leads") && classes.activeBorder,
                      classes.hoverBorder,
                      classes.nested
                    )}
                    onClick={() => routerLink("/crm/leads")}
                  >
                    <ListItemIcon
                      className={clsx(
                        matchRoute("/crm/leads")
                          ? classes.active
                          : classes.iconColor2
                      )}
                    >
                      LD
                    </ListItemIcon>
                    <ListItemText
                      primary="Leads"
                      className={clsx(
                        matchRoute("/crm/leads") && classes.active
                      )}
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={clsx(
                      matchRoute("/crm/salescall") && classes.activeBorder,
                      classes.hoverBorder,
                      classes.nested
                    )}
                    onClick={() => routerLink("/crm/salescall")}
                  >
                    <ListItemIcon
                      className={clsx(
                        matchRoute("/crm/salescall")
                          ? classes.active
                          : classes.iconColor2
                      )}
                    >
                      SC
                    </ListItemIcon>
                    <ListItemText
                      primary="Sales Call"
                      className={clsx(
                        matchRoute("/crm/salescall") && classes.active
                      )}
                    />
                  </ListItem>
                </List>
              </Collapse>

                        {isProjectMobDivision && ( <>

              <ListItem
                button
                onClick={() => handleDropdown("mpl")}
                className={classes.hoverBorder}
              >
                <ListItemIcon>
                  <WorkIcon className={classes.iconColor} />
                </ListItemIcon>
                <ListItemText primary="Project Mob." />
                {open.mpl ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open.mpl} timeout="auto" unmountOnExit>
                <List disablePadding>
                  <ListItem
                    button
                    onClick={() => routerLink("/pml/mobdemob")}
                    className={clsx(
                      matchRoute("/pml/mobdemob") && classes.activeBorder,
                      classes.hoverBorder,
                      classes.nested
                    )}
                  >
                    <ListItemIcon
                      className={clsx(
                        matchRoute("/pml/mobdemob")
                          ? classes.active
                          : classes.iconColor2
                      )}
                    >
                      MD
                    </ListItemIcon>
                    <ListItemText
                      primary="Mob Demob"
                      className={clsx(
                        matchRoute("/pml/mobdemob") && classes.active
                      )}
                    />
                  </ListItem>

                  <ListItem
                    button
                    onClick={() => routerLink("/pml/jobattendance")}
                    className={clsx(
                      matchRoute("/pml/jobattendance") && classes.activeBorder,
                      classes.hoverBorder,
                      classes.nested
                    )}
                  >
                    <ListItemIcon
                      className={clsx(
                        matchRoute("/pml/jobattendance")
                          ? classes.active
                          : classes.iconColor2
                      )}
                    >
                      JR
                    </ListItemIcon>
                    <ListItemText
                      primary="Job Attendance Log"
                      className={clsx(
                        matchRoute("/pml/jobattendance") && classes.active
                      )}
                    />
                  </ListItem>

                  <ListItem
                    button
                    onClick={() => routerLink("/pml/weeklyactivity")}
                    className={clsx(
                      matchRoute("/pml/weeklyactivity") && classes.activeBorder,
                      classes.hoverBorder,
                      classes.nested
                    )}
                  >
                    <ListItemIcon
                      className={clsx(
                        matchRoute("/pml/weeklyactivity")
                          ? classes.active
                          : classes.iconColor2
                      )}
                    >
                      WPA
                    </ListItemIcon>
                    <ListItemText
                      primary="Weekly Activity Report"
                      className={clsx(
                        matchRoute("/pml/weeklyactivity") && classes.active
                      )}
                    />
                  </ListItem>
                </List>
              </Collapse>
              </>)}


              {isAdmin && (
                <>
                  <ListItem
                    button
                    onClick={() => handleDropdown("setup")}
                    className={classes.hoverBorder}
                  >
                    <ListItemIcon>
                      <SettingsIcon className={classes.iconColor} />
                    </ListItemIcon>
                    <ListItemText primary="Setup" />
                    {open.setup ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={open.setup} timeout="auto" unmountOnExit>
                    <List disablePadding>
                      <ListItem
                        onClick={() => routerLink("/setup/offices")}
                        button
                        className={clsx(
                          matchRoute("/setup/offices") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/setup/offices")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          OF
                        </ListItemIcon>
                        <ListItemText
                          primary="Offices"
                          className={clsx(
                            matchRoute("/setup/offices") && classes.active
                          )}
                        />
                      </ListItem>
                      <ListItem
                        button
                        className={clsx(
                          matchRoute("/setup/division") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                        onClick={() => routerLink("/setup/division")}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/setup/division")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          DIV
                        </ListItemIcon>
                        <ListItemText
                          primary="Division"
                          className={clsx(
                            matchRoute("/setup/division") && classes.active
                          )}
                        />
                      </ListItem>
                      <ListItem
                        button
                        className={clsx(
                          matchRoute("/setup/jobtitle") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                        onClick={() => routerLink("/setup/jobtitle")}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/setup/jobtitle")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          JT
                        </ListItemIcon>
                        <ListItemText
                          primary="Job Title"
                          className={clsx(
                            matchRoute("/setup/jobtitle") && classes.active
                          )}
                        />
                      </ListItem>

                      <ListItem
                        button
                        className={clsx(
                          matchRoute("/setup/modules") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                        onClick={() => routerLink("/setup/modules")}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/setup/modules")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          MD
                        </ListItemIcon>
                        <ListItemText
                          primary="Modules"
                          className={clsx(
                            matchRoute("/setup/modules") && classes.active
                          )}
                        />
                      </ListItem>

                      <ListItem
                        button
                        className={clsx(
                          matchRoute("/setup/sequence") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                        onClick={() => routerLink("/setup/sequence")}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/setup/sequence")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          SQ
                        </ListItemIcon>
                        <ListItemText
                          primary="Sequence"
                          className={clsx(
                            matchRoute("/setup/sequence") && classes.active
                          )}
                        />
                      </ListItem>

                      <ListItem
                        button
                        className={clsx(
                          matchRoute("/setup/users") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                        onClick={() => routerLink("/setup/users")}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/setup/users")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          UL
                        </ListItemIcon>
                        <ListItemText
                          primary="Users List"
                          className={clsx(
                            matchRoute("/setup/users") && classes.active
                          )}
                        />
                      </ListItem>
                      <ListItem
                        button
                        className={clsx(
                          matchRoute("/setup/access/") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                        onClick={() => routerLink("/setup/access/")}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/setup/access/")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          AR
                        </ListItemIcon>
                        <ListItemText
                          primary="Access Rights"
                          className={clsx(
                            matchRoute("/setup/access/") && classes.active
                          )}
                        />
                      </ListItem>
                      <ListItem
                        button
                        className={clsx(
                          matchRoute("/setup/groups") && classes.activeBorder,
                          classes.hoverBorder,
                          classes.nested
                        )}
                        onClick={() => routerLink("/setup/groups")}
                      >
                        <ListItemIcon
                          className={clsx(
                            matchRoute("/setup/groups")
                              ? classes.active
                              : classes.iconColor2
                          )}
                        >
                          GRP
                        </ListItemIcon>
                        <ListItemText
                          primary="Groups"
                          className={clsx(
                            matchRoute("/setup/groups") && classes.active
                          )}
                        />
                      </ListItem>
                    </List>
                  </Collapse>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
